import request from '@/utils/request'

//------------------------- 测评问卷管理--------------------

// 测评问卷管理---修改、新增列表
export function saveQuesub(data) {
    return request({
        url: '/savequesub',
        method: 'post',
        data
    })
}
// 测评问卷管理--- 删除问卷
export function deleteQuesub(data) {
    return request({
        url: '/deletequesub',
        method: 'delete',
        params: data,
    })
}
// 测评问卷管理--- 获取问卷
export function getQesub(data) {
    return request({
        url: '/getquesub',
        method: 'get',
        params: data,
    })
}
// 查看问卷题目
export function setQsubject(data) {
    return request({
        url: '/set_qsubject',
        method: 'get',
        params: data,
    })
}
// 移除题目
export function delCertain(data) {
    return request({
        url: '/del_certain',
        method: 'get',
        params: data,
    })
}

// 设置总分
export function upCores(data) {
    return request({
        url: '/up_cores',
        method: 'post',
        data
    })
}




//------------------------- 测评分类管理--------------------
// 测评问卷管理--- 新增、修改
export function saveType(data) {
    return request({
        url: '/savetype',
        method: 'post',
        data
    })
}

// 测评问卷管理--- 列表
export function getType(data) {
    return request({
        url: '/gettype',
        method: 'get',
        params: data,
    })
}

// 测评问卷管理--- 删除
export function deleteType(data) {
    return request({
        url: '/deletetype',
        method: 'delete',
        params: data,
    })
}

// -------------------- 问卷题库管理 ---------------------

// 查询问卷题目列表
export function getQue(data) {
    return request({
        url: '/getque',
        method: 'get',
        params: data,
    })
}

// 删除问卷题目 
export function deleteQue(data) {
    return request({
        url: '/deleteque',
        method: 'delete',
        params: data,
    })
}

// 添加、修改问卷题目
export function saveQue(data) {
    return request({
        url: '/saveque',
        method: 'post',
        data
    })
}

//  问卷批阅试卷-列表加载
export function listCheckUserQue(data) {
    return request({
        url: '/list_check_user_que',
        method: 'get',
        params: data,
    })
}
// 批阅问卷，用户列表
export function listIsanswerUserQue(data) {
    return request({
        url: '/list_isanswer_user_que',
        method: 'get',
        params: data,
    })
}
// 获取用户问卷答题结果

export function getQueanswer(data) {
    return request({
        url: '/getqueanswer',
        method: 'get',
        params: data,
    })
}
//  批阅用户问卷
export function reviewque(data) {
    return request({
        url: '/reviewque',
        method: 'post',
        data
    })
}

// 批阅用户问卷删除
export function softDeleteAnswer(data) {
    return request({
        url: '/soft_delete_answer',
        method: 'post',
        data
    })
}